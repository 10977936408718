import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Layout from "../components/layout";
import Seo from "../components/seo";

import {graphql } from "gatsby";
import ResultTable from "./resultTable";

type PageTypes={
    path:string,
    pageContext:{
        marksheetData:{
            batchId: string,
            batchName: string,
            courseName: string,
            courseStatus: string,
            createdBy: string,
            currentBatchQuarterId: string,
            currentQuarter: string,
            currentlyOnsite: string,
            dropOut: string,
            dropOutReason: string,
            isActive: string,
            isGraduated: string,
            mongodb_id: string,
            rollNo: string,
            studentId: string,
            updatedBy: string
        }
    },
    data:{
        allMongodbPiaicProddbStudentQuarterResults:{
            group:{
                edges:{
                    node:{
                        batch:string,
                        course:string,
                        grade:string,
                        isPassed:string,
                        mongodb_id:string,
                        quarter:string,
                        rollNo:string,
                        percentile:string,
                        rank:string
                    }
                }[]
                totalCount:Number
            }[]
        },
        mongodbPiaicProddbRegstudents:{
            fullName:string,
            email:string,
            rollNo:string,
            imageUrl:string
        },
        allMongodbPiaicProddbBatchquizzes:{
            nodes:{
                quarter:string,
                quizName:string,
                course:string,
                batch:[]
            }[]
        },
        allMongodbPiaicProddbCourses:{
            nodes:{
                name:string
                shortDescription:string
                initial:string
            }[]
        }
    }
}

const StudenInfoArea = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: "3.3vw",
    },
    [theme.breakpoints.only('sm')]: {
        fontSize: "1.9vw",
    },
    fontSize: "1.3vw",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#017969",
    paddingLeft:"20px"
}));

const LogoTitle = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: "3.3vw",
        "& .AdminLogin-m-r-l":{
            width:"10vw !important"
        },
        "& h2":{
            fontSize:"3.8vw !important",
            margin: "0 0 1rem !important"
        },
    },
    fontSize: "1vw",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#017969",
    marginTop: "0px",
}))

const StudentImageForUpSmSize = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display:"block",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
        display:"none"
    },
    [theme.breakpoints.only('sm')]: {
        "& img":{
            width:"15vw !important",
            marginLeft: "55px !important",
            marginTop: "15px"
        }
    },
    "& img":{
        maxWidth:"1000% !important",
        marginBottom: "0rem"
    }
}))

const StudentImageForDownSmSize = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display:"block",
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        paddingLeft: '16px'
    },
    [theme.breakpoints.up('sm')]: {
        display:"none"
    },
    // "& .studentPicUp":{
    //     width:"25vw !important"
    // }
    "& img":{
        width:"26vw !important",
        marginBottom: "0rem"
    }
}))

const Marksheet: React.FC<PageTypes> = ({ pageContext:PagePropData, path ,data}) => {
    const {mongodbPiaicProddbRegstudents,allMongodbPiaicProddbBatchquizzes}=data
    // console.log("allMongodbPiaicProddbBatchquizzes",allMongodbPiaicProddbBatchquizzes)
    const breakUrl = mongodbPiaicProddbRegstudents.imageUrl.split('upload')
    const ImageBySize = breakUrl[0]+"upload/w_200,h_200,c_fill"+breakUrl[1]
    return (<Layout>
        <Seo title={mongodbPiaicProddbRegstudents && mongodbPiaicProddbRegstudents.fullName} description={mongodbPiaicProddbRegstudents.rollNo} image={ImageBySize} />
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} width="100%" marginLeft="-8px">
                <Grid item xs={12} md={12} >
                    <LogoTitle>
                        <StaticImage
                            src='../assets/LogoPIAIC.svg'
                            alt="PIAIC Logo"
                            title="Presidential Initiative for Artificial Intelligence and Computing"
                            className="AdminLogin-m-r-l"
                            style={{width:"4vw",margin:"10px"}}
                        />
                        <div>
                            Presidential Initiative for <br /> Artificial Intelligence and
                            Computing <br/><br/> <h2 style={{fontSize:"1.8vw"}}>PERFORMANCE STATEMENT</h2>
                        </div>
                    </LogoTitle>
                </Grid>
                <StudentImageForDownSmSize>
                    <Grid item xs={12} sm={4} md={4} textAlign="center">
                        {/* <StaticImage
                            src={mongodbPiaicProddbRegstudents.imageUrl}
                            className="studentPicUp"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="A Gatsby astronaut"
                            style={{width:"13vw"}}
                        /> */}
                        <div className="studentPicUp">
                            <img src={mongodbPiaicProddbRegstudents.imageUrl} style={{width:"13vw"}}/>
                        </div>
                    </Grid>
                </StudentImageForDownSmSize>
                <Grid item xs={12} sm={8} md={8}>
                    <StudenInfoArea>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sm={4} md={4}>
                                    NAME :
                                </Grid>
                                <Grid item xs={7} sm={6} md={6}>
                                    {mongodbPiaicProddbRegstudents && mongodbPiaicProddbRegstudents.fullName}
                                </Grid>
                                <Grid item xs={5} sm={4} md={4}>
                                    ENROLLMENT NO :
                                </Grid>
                                <Grid item xs={7} sm={6} md={6}>
                                    {mongodbPiaicProddbRegstudents && mongodbPiaicProddbRegstudents.rollNo}
                                </Grid>
                                <Grid item xs={5} sm={4} md={4}>
                                    EMAIL :
                                </Grid>
                                <Grid item xs={7} sm={6} md={6}>
                                    {mongodbPiaicProddbRegstudents && mongodbPiaicProddbRegstudents.email}
                                </Grid>
                            </Grid>
                        </Box>
                    </StudenInfoArea>
                </Grid>
                <StudentImageForUpSmSize>
                    <Grid item xs={12} sm={4} md={4} textAlign="center">
                        {/* <StaticImage
                            className="studentPicDown"
                            src="../assets/aic.jpg"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="A Gatsby astronaut"
                            style={{width:"13vw"}}
                        /> */}
                        <div className="studentPicDown">
                            <img src={mongodbPiaicProddbRegstudents.imageUrl} style={{width:"13vw"}}/>
                        </div>
                    </Grid>
                </StudentImageForUpSmSize>
                <Grid item xs={12} md={12} paddingTop="14px" paddingBottom="14px">
                    <ResultTable StudentResult={data} PassDataToTable={PagePropData}/>
                </Grid>
                <Grid item xs={12} md={12} paddingTop="14px" paddingBottom="14px" marginLeft="-8px">
                    <StudenInfoArea>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={5} marginLeft="0px" width="100%" marginTop="0px;">
                                <Grid item xs={12} sx={{ fontSize: 14 }}  textAlign="center" paddingTop="1px !important" padding="10px !important">
                                    © {new Date().getFullYear()} by PIAIC. All Rights Reserverd
                                </Grid>
                            </Grid>
                        </Box>
                    </StudenInfoArea>
                </Grid>
            </Grid>
        </Box>
    </Layout>)
}

export default Marksheet

export const query = graphql`
    query GetStudentQuarterResults($rollNo: String) {
        allMongodbPiaicProddbStudentQuarterResults(filter: {rollNo: {eq: $rollNo}} sort: {fields: quarter, order: ASC}) {
            group(field: course) {
                edges {
                    node {
                        rollNo
                        quarter
                        mongodb_id
                        isPassed
                        grade
                        course
                        batch
                        percentile
                        rank
                    }
                }
                totalCount
            }
        }
        mongodbPiaicProddbRegstudents(rollNo: {eq: $rollNo}) {
            fullName
            email
            rollNo
            imageUrl
        }
        allMongodbPiaicProddbBatchquizzes(sort: {order: ASC, fields: order}) {
            nodes {
                quarter
                quizName
                quizDb_id
                course 
                batch
            }
        }
        allMongodbPiaicProddbCourses {
            nodes {
                name
                shortDescription
                initial
            }
        }
    }
`