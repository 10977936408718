import * as React from "react"
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {useStaticQuery, graphql } from 'gatsby'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface UserDataProps {
    PassDataToTable:{
        marksheetData:{
            batchId: string,
            batchName: string,
            courseName: string,
            courseStatus: string,
            createdBy: string,
            currentBatchQuarterId: string,
            currentQuarter: string,
            currentlyOnsite: string,
            dropOut: string,
            dropOutReason: string,
            isActive: string,
            isGraduated: string,
            mongodb_id: string,
            rollNo: string,
            studentId: string,
            updatedBy: string
        }
    },
    StudentResult:{
        allMongodbPiaicProddbStudentQuarterResults:{
            group:{
                edges:{
                    node:{
                        batch:string,
                        course:string,
                        grade:string,
                        isPassed:string,
                        mongodb_id:string,
                        quarter:string,
                        rollNo:string,
                        percentile:string,
                        rank:string
                    }
                }[]
                totalCount:Number
            }[]
        },
        mongodbPiaicProddbRegstudents:{
            fullName:string,
            email:string,
            rollNo:string,
            imageUrl:string
        },
        allMongodbPiaicProddbBatchquizzes:{
            nodes:{
                quarter:string,
                quizName:string,
                course:string, 
                batch:[]
            }[]
        },
        allMongodbPiaicProddbCourses:{
            nodes:{
                name:string
                shortDescription:string
                initial:string
            }[]
        }
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#017969',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    [`&.${Grid}`]:{
        border:"0px"
    }
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const StudentTableArea = styled(Grid)(({ theme }) => ({
    fontSize: "1.5vw",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    marginTop: "50px",
    margin: "10px",
    border: "1px solid",
    padding: "10px",
    boxShadow: "5px 10px #888888",
}))

const resultTable: React.FC<UserDataProps> = ({PassDataToTable,StudentResult}) => {
    const {allMongodbPiaicProddbStudentQuarterResults,allMongodbPiaicProddbBatchquizzes,allMongodbPiaicProddbCourses}=StudentResult
    const data = useStaticQuery(graphql`
        query {
            allMongodbPiaicProddbStudentQuarterResults {
                group(field: course) {
                    group(field: batch) {
                        group(field: quarter) {
                            nodes {
                                batch
                                course
                                rollNo
                                quarter
                                grade
                            }
                            totalCount
                            max(field: rank)
                        }
                    }
                }
            }
        }
    `)
    return <React.Fragment>
        {allMongodbPiaicProddbStudentQuarterResults && allMongodbPiaicProddbStudentQuarterResults.group.map((value,index)=>(
            value.totalCount===4&&(    
                <>
                    <StudentTableArea>
                            <Grid container item xs={12} md={12} paddingTop="14px" paddingBottom="14px">
                                <TableContainer >
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center" colSpan={5} style={{fontSize:'21px'}}>
                                                    {allMongodbPiaicProddbCourses.nodes.map((eachCourse)=>(
                                                        eachCourse.initial===value.edges[0].node.course&&eachCourse.name+" ("+value.edges[0].node.course+") - "+value.edges[0].node.batch
                                                    ))}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell style={{paddingLeft:'55px'}}>Quarter</StyledTableCell>
                                                <StyledTableCell align="left">Exams</StyledTableCell>
                                                <StyledTableCell align="center">Percentile</StyledTableCell>
                                                <StyledTableCell align="center">Grade</StyledTableCell>
                                                <StyledTableCell align="center">Rank</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { value.edges.map((resultData)=>(
                                            <StyledTableRow>
                                            <StyledTableCell style={{paddingLeft:'55px'}} component="th" scope="row">
                                                {resultData.node.quarter.replace('Q','Quarter')}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <div>
                                                    {allMongodbPiaicProddbBatchquizzes.nodes.map((eachQuizz)=>(
                                                        resultData.node.course===eachQuizz.course&&
                                                        eachQuizz.batch.map((singleBatch)=>{
                                                            return resultData.node.batch===singleBatch&&resultData.node.quarter===eachQuizz.quarter?<span style={{whiteSpace: 'pre'}} key={eachQuizz.batch+"1"}>{eachQuizz.quizName+'\n'}</span>:""
                                                        })
                                                    ))}
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{resultData.node.percentile}</StyledTableCell>
                                            <StyledTableCell align="center">{resultData.node.grade}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {data.allMongodbPiaicProddbStudentQuarterResults.group.map((courseGroup)=>(
                                                    courseGroup.group.map((batchGroup)=>(
                                                        batchGroup.group.map((quarterGroup)=>{
                                                            return (quarterGroup.nodes[0].batch===resultData.node.batch&&quarterGroup.nodes[0].course===resultData.node.course&&quarterGroup.nodes[0].quarter===resultData.node.quarter&&
                                                                <span>{resultData.node.rank+"/"+quarterGroup.max}</span>
                                                            )
                                                        })
                                                    ))
                                                ))}
                                            </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                    </StudentTableArea>
                </>
            )
        ))}
        <Typography sx={{ fontSize: 14 }} color="text.secondary" padding="11px 11px" gutterBottom>
            <div style={{margin:"8px 20px"}}>
                <i><b>Grading: </b><br/>
                Students will be graded based on Percentile<br/> 
                <a href="https://en.wikipedia.org/wiki/Percentile">https://en.wikipedia.org/wiki/Percentile</a> <br/>
                <a href="https://en.wikipedia.org/wiki/Percentile_rank">https://en.wikipedia.org/wiki/Percentile_rank</a><br/> 
                - A-Grade: 78 - 99 Percentile<br/>
                - B-Grade: 41 - 77 Percentile<br/>
                - C-Grade: 23 - 40 Percentile<br/>
                - F-Grade:   1 - 22 Percentile<br/>
                Anyone who is in the bottom 22th Percentile i.e. F Grade will deem to have failed and will not be promoted to the next quarter and will be removed from the program.</i>
            </div>
        </Typography>
        </React.Fragment>
}

export default resultTable